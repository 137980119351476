import React, { Suspense, lazy } from "react"
import { graphql } from "gatsby"
import { Seo } from "../components/Seo"
import { Layout } from "../components/Layout"
import {
  useCertificationsData,
  useHomeHeroData,
  useHomeSeoData,
  PageSpecificSchemaFunction,
  useWhyChooseData,
  useServicesData,
  useWelcomeData,
  useHomeCallout,
  useTestimonialsData,
  useHomeBlogsData,
  useServiceAreasData,
  useHomeFinancingData,
  useHomeCouponsData,
  useFeaturedServicesData,
  useButtonTextData,
  useContactData,
  useForReviewsWidget,
} from "../components/DataImports"
import { PageSpecificSchema } from "@ashleynexvelsolutions/page-specific-schema"
import HomeHero from "../components/HomeHero"

//Use only for below the fold stuff.
const Welcome = lazy(() => import("../components/Welcome"))
const Services = lazy(() => import("../components/Services"))
const Callout = lazy(() => import("../components/Callout"))
const Testimonial = lazy(() => import("../components/Testimonial"))
const HomeBlogs = lazy(() => import("../components/HomeBlogs"))
const ServiceAreas = lazy(() => import("../components/ServiceAreas"))
const HomeFinancing = lazy(() => import("../components/HomeFinancing"))
const Coupons = lazy(() => import("../components/Coupons"))
const FeaturedServices = lazy(() => import("../components/FeaturedServices"))
const WhyChoose = lazy(() =>
  import("../components/WhyChoose").then(module => ({
    default: module.WhyChoose,
  }))
)
const Certifications = lazy(() =>
  import("../components/Certifications").then(module => ({
    default: module.Certifications,
  }))
)

const Home = ({ data }) => {
  const seo = useHomeSeoData()
  const whyChooseData = useWhyChooseData()
  const certificationsData = useCertificationsData()
  const heroData = useHomeHeroData()
  const welcomeData = useWelcomeData()
  const servicesData = useServicesData()
  const calloutData = useHomeCallout()
  const testimonialData = useTestimonialsData()
  const homeBlogsData = useHomeBlogsData()
  const serviceAreasData = useServiceAreasData()
  const homeFinancingData = useHomeFinancingData()
  const homeCouponsData = useHomeCouponsData()
  const featuresServicesData = useFeaturedServicesData()
  const buttonText = useButtonTextData()
  const contactData = useContactData()
  const reviewsRatings = useForReviewsWidget()

  return (
    <>
      <Seo data={seo} />
      <PageSpecificSchema {...PageSpecificSchemaFunction(data)} />
      <Layout data={data}>
        {/*Kevin's components part 1 */}
        <HomeHero data={heroData} />
        <Suspense fallback="loading">
          <Welcome data={welcomeData} />
        </Suspense>
        <Suspense fallback="loading">
          <Services
            data={servicesData}
            buttonText={buttonText}
            formData={contactData}
          />
        </Suspense>
        <Suspense fallback="loading">
          <Callout data={calloutData} />
        </Suspense>
        <Suspense fallback="loading">
          <Testimonial data={testimonialData} reviewsRatings={reviewsRatings} />
        </Suspense>
        {/*End Kevin's component's part 1 */}
        {/*Ashley's home page components */}
        <Suspense fallback="loading">
          <WhyChoose data={whyChooseData} />
        </Suspense>
        <Suspense fallback="loading">
          <Certifications data={certificationsData} />
        </Suspense>
        {/*End Ashley's home page components */}
        {/*Kevin's components part 2 */}
        <Suspense fallback="loading">
          <Coupons data={homeCouponsData} />
        </Suspense>
        <Suspense fallback="loading">
          <FeaturedServices
            data={featuresServicesData}
            buttonText={buttonText}
            formData={contactData}
          />
        </Suspense>
        <Suspense fallback="loading">
          <HomeFinancing
            data={homeFinancingData}
            buttonText={buttonText}
            formData={contactData}
          />
        </Suspense>
        <Suspense fallback="loading">
          <HomeBlogs data={homeBlogsData} />
        </Suspense>
        <Suspense fallback="loading">
          <ServiceAreas
            data={serviceAreasData}
            buttonText={buttonText}
            formData={contactData}
          />
        </Suspense>
        {/*End Kevin's component's part 2 */}
        {/*Ashley's footer will go below Areas We Serve. However, it will really be placed in the Layout component since it's on every page, just like the Header component is.*/}
      </Layout>
    </>
  )
}
export const query = graphql`
  {
    allWpPost(limit: 6, sort: { fields: date, order: DESC }) {
      edges {
        node {
          excerpt
          date(formatString: "MMM DD, YYYY")
          title
          link
        }
      }
    }
    wpPage(title: { eq: "Home" }) {
      seo {
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      nexvelschemapagesposts {
        videos {
          title
          url
          description
          thumbnailImage {
            localFile {
              publicURL
            }
          }
          uploadDate
        }
        questionsAndAnswers {
          question
          answer
        }
        maps {
          mapUrl
        }
        digitaldocuments {
          title
        }
        images {
          image {
            localFile {
              publicURL
            }
            date(formatString: "LL")
            description
            title
          }
        }
        areaServedLandingPageSchema
        serviceSchema {
          serviceNameSchema
          linkToRelevantServicePageSchema
        }
        keywordsSchema {
          keywordSchema
        }
        localAwardsSchema {
          localAwardSchema
        }
        latitudeSchema
        longitudeSchema
        relevantExternalPagesSchema {
          relevantExternalPageSchema
        }
      }
    }
    wp {
      nexvelSchemaMarkup {
        nexvelschema {
          cities {
            city
          }
          services {
            service
            linkToRelevantPageOnYourWebsite
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
export default Home
