import React, { useState } from "react"
import ReactPlayer from "react-player"
import Modal from "react-modal"
import { Link } from "gatsby"

import {
  SectionHomeHero,
  BannerLogo,
  Content,
  Heading,
  SubHeading,
  TodayCoupons,
} from "./index.style"

const HomeHero = ({ data }) => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false)

  return (
    <SectionHomeHero>
      <div className="background-video">
        <div
          style={{
            backgroundImage: `url(${data.backgroundImage})`,
          }}
          className="bgVideoImage"
        />
        <div className="background-video__container">
          <ReactPlayer
            url={data.backgroundVideoUrl}
            width="100%"
            height="100%"
            loop
            playing
            playsinline
            muted
            controls={false}
            className="react-player"
          />
        </div>
      </div>
      <button
        className="btn-play"
        onClick={() => {
          setIsVideoModalOpen(true)
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: data.playButton }} />
      </button>

      <Content>
        <BannerLogo
          image={data.secondaryHeroImage}
          alt={data.secondaryHeroImageAltText}
        />
        <Heading>{data.textColored}</Heading>
        <SubHeading>{data.textWhite}</SubHeading>
        <Link to="/offers">
          <TodayCoupons>{data.buttonText}</TodayCoupons>
        </Link>
      </Content>

      <Modal
        isOpen={isVideoModalOpen}
        onRequestClose={() => {
          setIsVideoModalOpen(false)
        }}
        // style={customStyles}
        contentLabel="Example Modal"
        style={{
          overlay: {
            zIndex: 99,
          },
          content: {
            inset: "80px",
          },
        }}
      >
        <ReactPlayer
          url={data.fullVideoUrl}
          width="100%"
          height="100%"
          loop
          playing
          playsinline
          controls={false}
        />
      </Modal>
    </SectionHomeHero>
  )
}

export default HomeHero
