import styled from "@emotion/styled/macro"
import { GatsbyImage } from "gatsby-plugin-image"
import { theme, device } from "../Theme"
import { Container } from "../../styles/Common"

const SectionHomeHero = styled.section`
  position: relative;
  box-sizing: border-box;
  z-index: 1;
  isolation: isolate;

  .background-video {
    padding-top: 56.4%;
    z-index: -1;
    width: 100%;
    position: relative;
    overflow: hidden;
    isolation: isolate;

    &:before {
      content: " ";
      position: absolute;
      left: 0;
      right: 0;
      top: -1px;
      bottom: calc(50% - 100px);
      background: #5a0000;
      z-index: 1;

      mix-blend-mode: multiply;
      clip-path: polygon(0 0, 100% 0, 100% calc(100% - 100px), 0 100%);

      @media ${device.lg} {
        left: 0;
        top: 0;
        bottom: 0;
        right: calc(47% - 180px);
        clip-path: polygon(0 0, calc(100% - 360px) 0, 100% 100%, 0 100%);
        background: #de292a;
      }
    }

    @media ${device.max_lg} {
      padding-top: 0;
      height: 539px;
    }

    &__container {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      @media ${device.max_lg} {
        transform: translate(-50%, 0);
      }
    }

    .react-player {
      position: relative;
      width: auto;

      @media ${device.max_lg} {
        width: 200% !important;
      }

      @media ${device.max_md} {
        width: 175% !important;
      }

      @media ${device.max_sm} {
        width: 220% !important;
      }

      @media (max-width: 450px) {
        width: 280% !important;
      }

      > div {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
      }
    }
  }

  button.btn-play {
    border: none;
    background: transparent;
    position: absolute;
    top: 50%;
    left: 53%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    display: none;
    z-index: 9;

    @media ${device.xl} {
      display: block;
    }
  }
  .bgVideoImage {
    width: 100%;
    position: absolute;
    inset: 0;
    z-index: -1;
  }
`

const Content = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media ${device.max_lg} {
    justify-content: flex-start;
    align-items: center;
  }

  button.btn-today {
    margin-bottom: 46px;

    @media ${device.max_xl} {
      margin-bottom: 0;
    }
  }
`

const BannerLogo = styled(GatsbyImage)`
  @media ${device.max_lg} {
    position: absolute;
    bottom: 50px;
    width: 298px;
    z-index: 1;
  }
`

const Heading = styled.span`
  margin-top: 56px;
  font-size: 77px;
  margin-bottom: 0;
  color: #e82223;
  text-transform: uppercase;
  font-weight: 700;
  font-family: ${theme.fonts.altFont};

  @media ${device.max_xl} {
    font-size: 26px;
  }
`

const SubHeading = styled.span`
  font-size: 90px;
  color: #fff;
  text-transform: uppercase;
  margin: 0 0 26px;
  max-width: 680px;
  font-family: ${theme.fonts.main};
  font-weight: 800;
  letter-spacing: -1.8px;
  line-height: 86px;

  @media ${device.max_lg} {
    font-size: 40px;
    text-align: center;
    line-height: 41px;
    margin: 0 0 14px;
    padding: 0 3rem;
  }
`

const TodayCoupons = styled.div`
  width: 213px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.primary};
  font-family: ${theme.fonts.altFont};
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 2px;
  color: ${theme.colors.light};
  text-transform: uppercase;

  @media ${device.lg} {
    width: 258px;
    height: 57px;
    font-size: 17px;
  }
`

export {
  SectionHomeHero,
  BannerLogo,
  Content,
  Heading,
  SubHeading,
  TodayCoupons,
}
